export default {
  APP: {
    BASE_URL: 'https://gdemo.compilorama.com'
  },
  ANALYTICS: {
    ENABLED: true,
    SRC: 'https://statorama.vercel.app/script.js',
    ID: 'a781b330-d3a1-4e83-94ca-14b4e43d42a0'
  }
};
